// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;

}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/Layout/Loading/loading.css"],"names":[],"mappings":";AACA;;EAEE,sBAAsB;;AAExB;AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,UAAU;EACV,kBAAkB;EAClB,8DAA8D;AAChE;AACA;EACE,sBAAsB;AACxB;AACA;EACE;IACE,SAAS;IACT,UAAU;IACV,UAAU;IACV,WAAW;IACX,UAAU;EACZ;EACA;IACE,SAAS;IACT,UAAU;IACV,UAAU;IACV,WAAW;IACX,UAAU;EACZ;EACA;IACE,SAAS;IACT,UAAU;IACV,UAAU;IACV,WAAW;IACX,UAAU;EACZ;EACA;IACE,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,UAAU;EACZ;AACF","sourcesContent":["\n.lds-ripple,\n.lds-ripple div {\n  box-sizing: border-box;\n\n}\n.lds-ripple {\n  display: inline-block;\n  position: relative;\n  width: 80px;\n  height: 80px;\n  justify-content: center;\n  align-items: center;\n}\n.lds-ripple div {\n  position: absolute;\n  border: 4px solid currentColor;\n  opacity: 1;\n  border-radius: 50%;\n  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;\n}\n.lds-ripple div:nth-child(2) {\n  animation-delay: -0.5s;\n}\n@keyframes lds-ripple {\n  0% {\n    top: 36px;\n    left: 36px;\n    width: 8px;\n    height: 8px;\n    opacity: 0;\n  }\n  4.9% {\n    top: 36px;\n    left: 36px;\n    width: 8px;\n    height: 8px;\n    opacity: 0;\n  }\n  5% {\n    top: 36px;\n    left: 36px;\n    width: 8px;\n    height: 8px;\n    opacity: 1;\n  }\n  100% {\n    top: 0;\n    left: 0;\n    width: 80px;\n    height: 80px;\n    opacity: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
