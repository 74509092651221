import "./App.css";

import { Route, Router, Routes } from "react-router-dom";

import { Suspense, lazy } from "react";
import Loading from "./components/Layout/Loading/Loading.js";
const LazyHome = lazy(() => import("./pages/home/Home.js"));
const LazyContact = lazy(() => import("./pages/contact/ContactUs.js"));
const LazyProduct = lazy(() => import("./pages/Products/Products.js"));
const LazyAboutUs = lazy(() => import("./pages/AboutUs/AboutUs.js"));
const LazyBlog = lazy(() => import("./pages/Resources/Blog.js"));
const LazyWhitepaper = lazy(() => import("./pages/Resources/Whitepaper.js"));
const LazyCaseStudy = lazy(() => import("./pages/Resources/CaseStudy.js"));
const LazySolution = lazy(() => import("./pages/Solutions/Solution.js"));
const LazyError = lazy(() => import("./components/Layout/Error/Error.js"));
const LazyDMForecast = lazy(() =>
  import("./components/Layout/DemandForecasting/DemandForecasting.js")
);
const LazyInOptimization = lazy(() =>
  import("./components/Layout/InventoryOptimization/InventoryOptimization.js")
);
const LazyPricePromotion = lazy(() =>
  import("./components/Layout/PricingPromotion/PricingPromotion.js")
);

const LazyAssortment = lazy(() =>
  import("./components/Layout/AssortmentOptimization/AssortmentOptimization.js")
);

const LazyADPlanningSystem = lazy(() =>
  import("./components/Layout/AdvancedPlanningSystem/AdvancedPlanningSystem.js")
);

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <LazyHome />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<Loading />}>
              <LazyAboutUs />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense fallback={<Loading />}>
              <LazyContact />
            </Suspense>
          }
        />
        <Route
          path="/product"
          element={
            <Suspense fallback={<Loading />}>
              <LazyProduct />
            </Suspense>
          }
        />
        <Route
          path="/solution"
          element={
            <Suspense fallback={<Loading />}>
              <LazySolution />
            </Suspense>
          }
        ></Route>

        <Route
          path="/solution/demandForecast"
          element={
            <Suspense fallback={<Loading />}>
              <LazyDMForecast />
            </Suspense>
          }
        />
        <Route
          path="/solution/InOptimization"
          element={
            <Suspense fallback={<Loading />}>
              <LazyInOptimization />
            </Suspense>
          }
        />
        <Route
          path="/solution/pricepromo"
          element={
            <Suspense fallback={<Loading />}>
              <LazyPricePromotion />
            </Suspense>
          }
        />

        <Route
          path="/solution/assortment"
          element={
            <Suspense fallback={<Loading />}>
              <LazyAssortment />
            </Suspense>
          }
        />

        <Route
          path="/solution/adpsystem"
          element={
            <Suspense fallback={<Loading />}>
              <LazyADPlanningSystem />
            </Suspense>
          }
        />

        <Route
          path="/blog"
          element={
            <Suspense fallback={<Loading />}>
              <LazyBlog />
            </Suspense>
          }
        />
        <Route
          path="/whitepaper"
          element={
            <Suspense fallback={<Loading />}>
              <LazyWhitepaper />
            </Suspense>
          }
        />
        <Route
          path="/casestudy"
          element={
            <Suspense fallback={<Loading />}>
              <LazyCaseStudy />
            </Suspense>
          }
        />

        <Route
          path="/Error"
          element={
            <Suspense fallback={<Loading />}>
              <LazyError />
            </Suspense>
          }
        />
      </Routes>
      {/* <PageLoadTimeTracker /> */}
    </div>
  );
}

export default App;
